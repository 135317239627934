import axios, { type AxiosRequestConfig, type InternalAxiosRequestConfig, type Method } from 'axios'
import { hash } from 'ohash'
import Cookie from 'js-cookie'
import { EJavaBusinessResponseCode, EPHPBusinessResponseCode } from '~/enums/request'
import { useSharedStore } from '~/stores/shared'

interface CustomError {
  errorCode: number
  errorMessage: string
  errorCodeStr: string | null
}

export function isCustomError(error: any): error is CustomError {
  return error?.errorCode !== undefined && error?.errorCodeStr !== undefined && error?.errorMessage !== undefined
}

function getCookie(name: any) {
  if (typeof window === 'undefined') {
    return
  }
  const arr = document?.cookie.split('; ') // 多个cookie值是以; 分隔的，用split把cookie分割开并赋值给数组
  for (
    let i = 0;
    i < arr.length;
    i++ // 历遍数组
  ) {
    const arr2 = arr[i].split('=') // 原来割好的数组是：user=simon，再用split('=')分割成：user simon 这样可以通过arr2[0] arr2[1]来分别获取user和simon
    if (arr2[0] === name && arr2[1]) {
      // 如果数组的属性名等于传进来的name
      return arr2[1] // 就返回属性名对应的值
    }
  }
}

const { CancelToken } = axios
const source = CancelToken.source()

// import type { IrequestHeader } from '@/types/global';
const httpAxios = axios.create({
  timeout: 10000
})

// let showError = false

// 请求超时的等待时间,覆写库的超时默认值
// 在超时前，所有请求都会等待 6 秒
axios.defaults.timeout = 6000

// 全局请求拦截器
httpAxios.interceptors.request.use((config): InternalAxiosRequestConfig => {
  const headers = useRequestHeaders()
  const runtimeConfig = useRuntimeConfig()

  const key = hash(+new Date() + config?.url)

  const customHeader: any = {
    platform: 2,
    language: 'zh_CN'
  }

  const { JAVA_HOST, PHP_HOST } = runtimeConfig.public

  let BASE_URL: any = ''

  BASE_URL = config?.open ? PHP_HOST : JAVA_HOST
  // console.log('BASE_URL====', BASE_URL)
  // if (process.server) {
  //   BASE_URL = config?.open ? PHP_HOST : JAVA_HOST
  // } else {
  //   BASE_URL = `${config?.open ? PHP_HOST : JAVA_HOST}`
  // }
  if (config?.customBase && runtimeConfig.public[config?.customBase]) {
    BASE_URL = runtimeConfig.public[config?.customBase]
  }

  if (config?.urlencoded) {
    customHeader['content-type'] = 'multipart/form-data'
  }

  if (config.method === 'POST' && config?.open) {
    config.params = config?.query
  }

  if (process.client) {
    if (getCookie('C5AccessToken')) {
      customHeader.access_token = getCookie('C5AccessToken')
    } else if (getCookie('H5_C5_AccessToken')) {
      customHeader.access_token = getCookie('H5_C5_AccessToken')
    }

    customHeader.app_version_code = Cookie.get('C5AppVersion') || '401000'

    customHeader.channel = Cookie.get('C5Channel') || 'iOS'

    const douyuTag = Cookie.get('douyuTag') ? Cookie.get('douyuTag') : ''
    const from = Cookie.get('NC5_sem_from') ? Cookie.get('NC5_sem_from') : ''
    const keyword = Cookie.get('NC5_sem_keyword') ? Cookie.get('NC5_sem_keyword') : ''
    const clickExt = Cookie.get('NC5_sem_clickExt') ? Cookie.get('NC5_sem_clickExt') : ''
    const bdvid = Cookie.get('NC5_sem_bdvid') ? Cookie.get('NC5_sem_bdvid') : ''
    const sem360id = Cookie.get('NC5_sem360id') ? Cookie.get('NC5_sem360id') : ''
    const refererUrl = Cookie.get('NC5_sem_refererurl') ? Cookie.get('NC5_sem_refererurl') : ''
    const source = Cookie.get('NC5_activity_from') ? Cookie.get('NC5_activity_from') : ''

    if (douyuTag || from || keyword || bdvid || sem360id || source) {
      customHeader['x-channel'] = `${douyuTag || from || source};${keyword};${bdvid || clickExt || sem360id}`
      customHeader['x-referral-url'] = `${refererUrl}`
    }

    if (config?.url?.indexOf('http://') <= -1 && config?.url?.indexOf('https://') <= -1) {
      // eslint-disable-next-line no-empty
      if (config?.open) {
      } else {
        customHeader.language = headers.language ? headers.language.replace('-', '_') : 'zh_CN'
      }
      config.url = BASE_URL.includes('http') ? `${BASE_URL}${config?.url}` : `${window.location.origin}${BASE_URL}${config?.url}`
    }
  }

  customHeader.device = isInApp ? (customHeader.channel === 'iOS' ? 3 : 2) : 4
  // customHeader.device = 4
  const { fallbackDeviceId } = useSharedStore()
  customHeader.device_id = Cookie.get('C5DeviceId') ? Cookie.get('C5DeviceId') : fallbackDeviceId

  // steam 登录
  if (config?.url?.indexOf('v1/steam/login') >= 0) {
    customHeader.device = isInApp ? (customHeader.channel === 'iOS' ? 3 : 2) : 3
  }

  config.headers = {
    ...config.headers,
    platform: 2,
    ...customHeader
  }
  config.key = key
  config.baseURL = BASE_URL
  config.cancelToken = source.token

  return config
})

// 全局响应拦截器
httpAxios.interceptors.response.use(
  response => {
    const data = response?.data || response

    if ((data?.errorCode && data?.errorCode !== 0) || (data?.status && data?.status !== 200)) {
      // TODO toast 错误提示 ====》(根据传值判断是否需要展示)
      // console.error('错误提示', data)

      // 聚合错误信息
      const errorMessage = data?.message || data?.errorMsg || ''
      const errorCode = data?.status || data?.errorCode || ''

      if (response?.config?.hideToast) {
        // 不提示
      } else if (data?.errorCode !== 101 && data?.errorCode !== 30027 && data?.errorCode !== 'REPEAT_REQUEST') {
        // 针对未登录 及 请求重复 errorCode 不显示toast提示
        showToast(errorMessage)
      }

      switch (errorCode) {
        case EJavaBusinessResponseCode.NOT_LOGIN:
        case EPHPBusinessResponseCode.NOT_LOGIN:
          console.log('request：未登录')
          if (isInApp) {
            console.log('前往登录页面')
            native?.loginBackPage(location?.href)
          } else {
            Cookie.remove('H5_C5_AccessToken')
            navigateTo(`/login?returnUrl=${encodeURIComponent(location?.href)}`, { replace: true })
          }
          break
        case EJavaBusinessResponseCode.NOT_FOUND:
        case EJavaBusinessResponseCode.NOT_FOUND_4004:
          console.log('404')
          break
        case EJavaBusinessResponseCode.SERVER_ERROR:
        case EJavaBusinessResponseCode.SERVER_ERROR_999:
          console.log('服务器异常')
          break
        case EJavaBusinessResponseCode.MACHINE_VERIFICATION:
          console.log('需要人机校验')
          break
        case EJavaBusinessResponseCode.NOT_AUTHENTICATION:
          console.log('未认证')
          break
        case EJavaBusinessResponseCode.NOT_FULL_EIGHTEEN:
          console.log('未满十八岁')
          break
        default:
          console.log(data?.errorMsg || '')
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        ...data,
        errorMessage,
        errorCode
      })
    } else {
      // console.log('执行成功了', data)
      return Promise.resolve(data?.data)
    }

    // return response?.data
  },
  error => {
    return Promise.reject(error)
  }
)

interface Request extends AxiosRequestConfig {
  open?: boolean
  hideToast?: boolean
  customBase?: string
}

export function request({
  url,
  method,
  data,
  params,
  signal,
  open, // open ? 使用PHP接口 : 使用java接口
  hideToast, // 隐藏错误提示
  headers,
  customBase, // 使用自定义BASE URL, 一般仅在特定测试环境中使用
  ...rest
}: Request): any {
  if (!method) {
    throw new Error('method is required')
  }
  if (!url) {
    throw new Error('url is required')
  }

  method = method.toLocaleUpperCase() as Method
  data = data || {}
  params = params || {}

  return httpAxios<any>(url, {
    signal,
    method,
    data,
    params,
    open,
    hideToast,
    headers,
    customBase,
    ...rest
  })
}
